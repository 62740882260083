import type { ReactNode } from 'react'
import aiShoppingAgent from '@/assets/images/ai-shopping-agent.jpg'
import aiOnlineShopping from '@/assets/images/ai-online-shopping-agents.jpg'
import shoppingExperience from '@/assets/images/shopping-experience.jpg'
import shoppingTools from '@/assets/images/shopping-tool.jpg'
import roiAi from "@/assets/images/roi-ai.jpg"
import { normalizeTerm } from '@/utils/helper'
export interface Article {
  id: string;
  title: string;
  subtitle: string;
  backgroundColor: string;
  publishAt: string;
  image: string;
  content: ReactNode;
}


const articles: Article[] = [
  {
    title: "ROI of AI Shopping Agents",
    subtitle:
      "January, 2025 is a hectic month for AI development. The biggest development is Deepseek’s shock, which brought down $1T US stock value,  although the Deepseek-R1 model was actually...",
    backgroundColor: "#CBB9FF",
    publishAt: "Published on Feb 7, 2025",
    image: roiAi,
    content: (
      <>
        <p>
          January, 2025 is a hectic month for AI development. The biggest
          development is Deepseek’s shock, which brought down $1T US stock
          value, although the Deepseek-R1 model was actually released in late
          December, 2024. As the previous <a
            target="_blank"
            href="https://www.nubestore.ai/#/blog/cloudstore-a-tool-for-ai-shopping-agents"
            rel="noreferrer"
          >
            article
          </a>
          [1] mentioned, foundation models can be US-based models or China-based
          models. Foundation models can also be closed models or open models.
          Deepseek’s shock involved both these two dimensions. But besides these
          two dimensions, there is an extremely important third dimension, i.e.,
          cost or economics dimension. DeepSeek training cost (or the last
          training run) is $6M, while many state of the art models training cost
          is a few $10M’s [2] to $1B ($1B may be a tactic to scare away
          competitors, but obviously it backfired). It is clear now that the
          training cost can definitely be{" "}
          <strong>cheaper, much cheaper, and at least 10x cheaper</strong>. When
          something is 10x cheaper , it is a big change and people will try it
          and adopt it. As we build and empower AI shopping agents, we cheer on
          models which are more powerful, more widely adopted, and cheaper.
          Lower cost means better ROI opportunities for AI application builders
          such as AI shopping agent builders.
        </p>
        <p>
          For AI shopping agent builders, we care more about what is the cost of
          utilizing foundation models and what is ROI after we deploy AI
          shopping agents in the production. To utilize foundation models, we
          have two ways. One is to call the foundation model APIs directly. The
          other is to deploy an open model ourselves. For the foundation model
          API calls, i.e. inference, DeepSeek APIs are{" "}
          <strong>cheaper, much cheaper, and at least 10x cheaper</strong>.
          Check the latest pricing on these model providers’ websites. There may
          be a price war, which is good for AI shopping agent builders. Many
          people try to deploy open models themselves on cloud or local servers
          to further save the utilization cost. Last week, we saw people deploy
          the DeepSeek model on a $6000 local server. On January 31, we even saw
          people deploy the DeepSeek model on a $2000 local server [3]. The
          entry point for AI shopping agent builders is getting lower and lower.
          More people can join to build AI shopping agents, which is exciting
          news!
        </p>
        <p>
          Lower cost of utilizing foundation models is great news for AI
          shopping agent builders. But it is not enough. We have to make a{" "}
          <strong>real business impact</strong> when we deploy AI shopping
          agents in the production. If the core value of the AI shopping agent
          we build is to automate the shopping tasks, the AI shopping agent must
          save money than the previous non-AI way, i.e., the AI shopping agent
          must bring positive ROI in order to get wider adoption. If the cost of
          calling foundation model APIs alone is bigger than hiring a human to
          do the shopping tasks or using RPA (Robotic Programming Automation) to
          do the shopping tasks, the AI shopping agent will definitely not be
          widely adopted. We have to optimize the cost of calling the foundation
          model APIs. There are many possible ways to optimize the cost of
          calling the foundation model APIs. Here are three ways. The first is
          to call cheaper but decent quality foundation models such as
          DeepSeek-R, which is at least 10x cheaper. The second is to optimize
          the input tokens before we make API calls[4]. The third is to generate
          code once for all when we make foundation API calls. Then when we plan
          to make foundation API calls next time, execute the generated code
          instead of calling foundation model APIs to fulfill the task[4].
        </p>
        <p>
          <a
            href="https://www.nubestore.ai/#/blog/cloudstore-a-tool-for-ai-shopping-agents"
            target="_blank"
            rel="noreferrer"
          >
            [1]
            https://www.nubestore.ai/#/blog/cloudstore-a-tool-for-ai-shopping-agents
          </a>
          <br />
          <a
            href="https://darioamodei.com/on-deepseek-and-export-controls"
            target="_blank"
            rel="noreferrer"
          >
            [2] https://darioamodei.com/on-deepseek-and-export-controls
          </a>
          <br />
          <a
            href="https://www.youtube.com/watch?v=Tq_cmN4j2yY"
            target="_blank"
            rel="noreferrer"
          >
            [3] https://www.youtube.com/watch?v=Tq_cmN4j2yY
          </a>
          <br />
          <a
            href="https://www.zyte.com/webinars/efficient-web-scraping-with-llms/"
            target="_blank"
            rel="noreferrer"
          >
            [4] https://www.zyte.com/webinars/efficient-web-scraping-with-llms/
          </a>
          <br />
        </p>
      </>
    ),
  },
  {
    title: "CloudStore, a tool for AI Shopping Agents",
    subtitle:
      "AI agent is a term which has been used for decades. But here we are talking about an AI agent with a large language model. So you are making or plan to make an AI shopping agent by leveraging...",
    backgroundColor: "#F5F5F5",
    publishAt: "Published on Jan 27, 2025",
    image: shoppingTools,
    content: (
      <>
        <p>
          AI agent is a term which has been used for decades. But here we are
          talking about an AI agent with a large language model. So you are
          making or plan to make an AI shopping agent by leveraging a large
          language model (LLM). You have probably already decided which LLM
          model(s) to use, closed models such as Anthropic or Gemini, vs. open
          models such as Llama or Mistral, US-based models such as OpenAI or
          xAI, vs. international models such as DeepSeek or Alibaba’s Qwen. But
          as Google AI agent whitepaper[1] mentioned, you probably need to
          integrate with some tools in order to make AI shopping agents do
          amazing things for your customers.
        </p>
        <p>
          It is likely that you are focused on how to utilize LLMs to build an
          awesome agent and you need some external tools to help you do some
          shopping errands such as getting a merchandising catalog and making an
          order. You are not interested in doing this ecommerce plumbing work.
          Fortunately, there are some tools in the markets already available for
          this. CloudStore is such a tool. What can CloudStore do to empower
          your exciting AI shopping agent innovation?
        </p>
        <p>Here are 3 must-haves for your AI shopping agent.</p>
        <ul>
          <li>
            <strong>Catalog API.</strong> You can easily get a merchandising
            catalog from more than 300 merchants, 6000 American and European
            brands, and 10,000,000 merchandising SKUs. You do not need to find
            ways to find this huge merchandising supply since we have been
            building this in the last 10 years and we keep adding supplies and
            improving the quality of this merchandising catalog such as data
            freshness.
          </li>
          <li>
            <strong>Order API.</strong> If you are truly a shopping agent, one
            action is a must-have, i.e., order a product, say, a pair of
            sneakers for your customers. Ordering sounds simple, but it is messy
            engineering work in reality. Fortunately, CloudStore has
            successfully made 5,000, 000 orders and delivered more than
            $1,000,000,000 worth merchandising items for clients all over the
            world (USA, China, South Korea, Australia, South America, etc.).
          </li>
          <li>
            <strong>Logistics tracking API.</strong> Your AI shopping agent may
            keep customers informed about where ordered merchandising items are
            located. Logistics tracking API enables your AI shopping agent and
            your customers to know the step-by-step journey of merchandising
            items, maybe from the other side of the world, say, Manchester of UK
            to the doorstep of your customer home in Los Angeles, California. We
            have accumulated shipping performance data over 10 years and know
            which merchants to recommend.
          </li>
        </ul>
        <p>
          In addition, there are some additional tools such as Search API. If
          your shopping agent needs the search feature among 10,000,000+
          merchandising items and you do not want to focus on this feature, you
          do not need to reinvent the wheel since CloudStore already offer the
          search API.
        </p>
        <p>
          We strongly believe in the bright future of AI shopping agents.
          Different AI shopping agent innovation may cover the whole path of the
          shopping journey or only a part of the shopping journey. It may also
          get humans involved in the loop, as Langchain calls ambient agents[2].
          But no matter what, CloudStore will provide a strong toolkit to AI
          shopping agents to fulfill the shopping errands in the whole shopping
          journey.
        </p>
        <p>
          <a
            href="https://www.kaggle.com/whitepaper-agents"
            target="_blank"
            rel="noreferrer"
          >
            [1] https://www.kaggle.com/whitepaper-agents
          </a>
          <br />
          <a
            href="https://blog.langchain.dev/introducing-ambient-agents/"
            target="_blank"
            rel="noreferrer"
          >
            [2] https://blog.langchain.dev/introducing-ambient-agents/
          </a>
        </p>
      </>
    ),
  },
  {
    title: "AI Shopping Agent in 2025",
    backgroundColor: "#DCFF9B",
    subtitle:
      "The Age of AI Agents is here. AI shopping agents are evolving fast. Perplexity, Amazon (Rufus), and many other players are developing AI shopping agents or assistants. ",
    publishAt: "Published on Jan 15, 2025",
    image: aiShoppingAgent,
    content: (
      <>
        <p>
          The Age of AI Agents is here. AI shopping agents are evolving fast.
          Perplexity, Amazon (Rufus), and many other players are developing AI
          shopping agents or assistants.
        </p>
        <p>
          As consumer-facing agents, AI shopping agents or travel agents are
          more complex than enterprise-facing agents because of heterogeneity of
          consumer applications and complexity of understanding consumers. In
          order to prepare for a better future for the AI shopping agent world,
          we encourage ecommerce merchants to set a standard and open protocol
          about ecommerce infrastructure such as product catalog and order
          fulfillment.  It will accelerate AI shopping agent progress and
          improve agent accuracy. 
        </p>
        <p>
          The expectations for AI shopping agents or assistants span a broad
          spectrum, from basic tasks to sophisticated operations. CloudStore AI
          (
          <a target="_blank" href="https://www.nubestore.ai/" rel="noreferrer">
            https://www.nubestore.ai/
          </a>
          ) of BorderX Lab has emerged as a comprehensive tool to support these
          AI shopping agents across the entire shopping journey - from managing
          product catalogs, fulfilling orders, to tracking logistics, and
          handling returns.
        </p>
        <p>
          Ecommerce needs a lot of plumbing work, order fulfillment, logistics
          tracking, and return processing. Humans are definitely in the loop now
          for AI shopping agents. The work distribution of humans and AI is
          evolving. Eventually AI shopping agents can have the same skills as
          humans.
        </p>
        <p>Now is an exciting time to build AI shopping agents.</p>
      </>
    ),
  },
  {
    title: "AI Online Shopping Agents: The Future of E-commerce",
    backgroundColor: "#FAFAFA",
    subtitle:
      "The rapid advancements in AI, particularly with Large Language Models (LLMs), have made the development of AI Online Shopping Agents (AIOSAs) a real possibility. While online...",
    publishAt: "Published on Jan 14, 2025",
    image: aiOnlineShopping,
    content: (
      <>
        <p>
          The rapid advancements in AI, particularly with Large Language Models
          (LLMs), have made the development of AI Online Shopping Agents
          (AIOSAs) a real possibility. While online shopping is a common task
          for many, a successful purchase often requires significant time and
          effort. For instance, finding the perfect desk for my 8-year-old
          daughter on Amazon took nearly an hour. I had to compare multiple
          products, understand their features, read customer reviews, and select
          the right size and color. Even after receiving the item, there might
          be additional tasks if I'm unsatisfied with the purchase.
        </p>
        <p>
          In the foreseeable future, AI Online Shopping Agents can assist us in
          completing successful online shopping trips and save us time, energy,
          and money. For those who struggle with internet use, such as the
          elderly, the benefits would be even more pronounced.
        </p>
        <p>
          However, developing an AI Online Shopping Agent is not a trivial task.
          It presents several challenges:
        </p>
        <ul>
          <li>
            <strong>Interacting with the Internet:</strong> AI must be able to
            interact with the internet as humans do. While recent advancements
            in AI agents, like Anthropic's computer use, have made strides,
            there's still room for improvement.
          </li>
          <li>
            <strong>Domain Knowledge:</strong> AI needs to possess
            domain-specific knowledge about shopping. It's not inherently
            knowledgeable about every aspect of shopping. Each step in the
            shopping process requires prompts to guide the AI, telling it which
            websites to visit, tools to use, and so on.
          </li>
          <li>
            <strong>Human Interaction:</strong> AI must effectively interact
            with humans, following human guidance and making decisions under
            human supervision.
          </li>
          <li>
            <strong>Safety and Security:</strong> With AI hallucinations still
            being an issue, ensuring the accuracy and security of AI-driven
            actions is crucial.
          </li>
        </ul>
        <p>
          Given these challenges, we believe the development of AI Online
          Shopping Agents will be a gradual process. In the early stages,
          critical steps like placing orders may still require human-AI
          collaboration.
        </p>
        <p>
          At BorderX Lab, we're actively researching and developing AI Online
          Shopping Agents. With a decade of experience in e-commerce and RPA
          services, we're confident in the potential of AI Online Shopping
          Agents and believe we're well-positioned to succeed. For example, our
          cloud store product (
          <a target="_blank" href="https://www.nubestore.ai/" rel="noreferrer">
            https://www.nubestore.ai/
          </a>
          ) leverages RPA to automate order placement on major European and
          American fashion e-commerce sites with a success rate of 95%. This
          service can seamlessly integrate with AI to create AI Online Shopping
          Agents specifically for fashion e-commerce.
        </p>
      </>
    ),
  },
  {
    title:
      "AI Shopping Agents: Unlocking the Secrets of a Perfect Shopping Experience",
    backgroundColor: "#A3F4FF",
    subtitle:
      "Imagine a future where you wake up, and your AI shopping agent, integrated with your smart home system, has already analyzed the data from your smart fitness tracker. It knows you've been ...",
    publishAt: "Published on Jan 13, 2025",
    image: shoppingExperience,
    content: (
      <>
        <p>
          Imagine a future where you wake up, and your AI shopping agent,
          integrated with your smart home system, has already analyzed the data
          from your smart fitness tracker. It knows you've been pushing yourself
          hard at the gym, and as you enter the kitchen, a holographic display
          appears, showing you the latest high - performance sports gear that's
          perfect for your workouts. When you're getting ready for a virtual
          business meeting, the AI, having studied your past meeting outfits and
          the nature of the event, suggests a stylish, professional ensemble
          that can be ordered and delivered within hours. As you commute, the
          agent notifies you of a limited - edition art piece that matches your
          home decor preferences, and with a simple voice command, you can add
          it to your cart.
        </p>
        <p>
          Are these the kind of shopping experiences we all desire? In recent
          years, AI shopping agents have emerged as a revolutionary force within
          the dynamic e - commerce landscape. Giants like Amazon, leveraging the
          power of Alexa. Similarly, Alibaba, with its cutting - edge smart
          shopping assistants, has been leading the charge in the East. Their AI
          can not only understand complex natural language queries but also
          provide astute product recommendations. It can even smoothly complete
          purchases, making the shopping process as seamless as possible.
        </p>
        <p>
          However, this is just the tip of the iceberg. There remains an ocean
          of untapped potential for further development, such as enhancing the
          agents' ability to handle more nuanced emotions in customer requests
          and integrating with emerging technologies like augmented reality for
          a more immersive shopping experience.
        </p>
        <p>
          What sets BorderX Lab's Cloud Store apart from its competitors is
          truly extraordinary. It has established an incredibly robust fashion
          supply chain in both Europe and the United States. For instance, when
          Paris Fashion Week debuts the latest trends, the Cloud Store's AI
          shopping agents can quickly offer customers those exclusive pieces.
          This means fashion enthusiasts can get their hands on the most up - to
          - date and unique fashion items straight from these fashion - forward
          regions.
        </p>
        <p>
          The Cloud Store also houses a colossal database that towers over many
          others in the industry. It doesn't stop at fashion; it encompasses a
          broad range of products, from electronics to home decor. With real -
          time price and inventory updates, the AI agents ensure that customers
          are always in the know. No more disappointment of clicking on a
          product only to find it's out of stock or being charged an incorrect
          price.
        </p>
        <p>
          Moreover, its personalized data analytics are unrivaled. By delving
          deep into customers' shopping habits, preferences, and even style
          choices, the AI agents can offer customized recommendations like no
          other, creating a shopping experience that is uniquely tailored to
          each individual.
        </p>
        <p>
          The Cloud Store will serve as a huge product data repository. For
          instance, when a user searches for a high - end smartphone, the AI
          agent, leveraging the Cloud Store's data, can instantly list all
          available models from different brands, their features like camera
          quality, processing speed, and battery life, along with price
          comparisons across multiple online platforms.
        </p>
        <p>
          Advanced algorithms in the Cloud Store analyze customers' behaviors.
          Suppose a customer often buys fitness equipment. The AI agent can then
          suggest new yoga mats from emerging eco - friendly brands added to the
          Cloud Store, complete with user reviews.
        </p>
        <p>
          Moreover, for cross - border shopping, if a customer in the US wants
          to buy Japanese skincare products, the agent, using the Cloud Store's
          global network, can handle currency conversion, find the best - priced
          products, and sort out shipping details. This makes shopping more
          efficient and globally accessible.
        </p>
      </>
    ),
  },
].map((item) => ({
  ...item,
  id: normalizeTerm(item.title),
}));

export default articles;